// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelles-js": () => import("./../../../src/pages/aktuelles.js" /* webpackChunkName: "component---src-pages-aktuelles-js" */),
  "component---src-pages-altarretabel-js": () => import("./../../../src/pages/altarretabel.js" /* webpackChunkName: "component---src-pages-altarretabel-js" */),
  "component---src-pages-architekturflaeche-js": () => import("./../../../src/pages/architekturflaeche.js" /* webpackChunkName: "component---src-pages-architekturflaeche-js" */),
  "component---src-pages-aufbau-js": () => import("./../../../src/pages/aufbau.js" /* webpackChunkName: "component---src-pages-aufbau-js" */),
  "component---src-pages-datenschutzerklarung-js": () => import("./../../../src/pages/datenschutzerklärung.js" /* webpackChunkName: "component---src-pages-datenschutzerklarung-js" */),
  "component---src-pages-dokumentation-js": () => import("./../../../src/pages/dokumentation.js" /* webpackChunkName: "component---src-pages-dokumentation-js" */),
  "component---src-pages-holzbildwerke-js": () => import("./../../../src/pages/holzbildwerke.js" /* webpackChunkName: "component---src-pages-holzbildwerke-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leinwandgemaelde-js": () => import("./../../../src/pages/leinwandgemaelde.js" /* webpackChunkName: "component---src-pages-leinwandgemaelde-js" */),
  "component---src-pages-profanbau-js": () => import("./../../../src/pages/profanbau.js" /* webpackChunkName: "component---src-pages-profanbau-js" */),
  "component---src-pages-publikation-js": () => import("./../../../src/pages/publikation.js" /* webpackChunkName: "component---src-pages-publikation-js" */),
  "component---src-pages-referenzen-js": () => import("./../../../src/pages/referenzen.js" /* webpackChunkName: "component---src-pages-referenzen-js" */),
  "component---src-pages-sakralraum-js": () => import("./../../../src/pages/sakralraum.js" /* webpackChunkName: "component---src-pages-sakralraum-js" */),
  "component---src-pages-schlossinterieur-js": () => import("./../../../src/pages/schlossinterieur.js" /* webpackChunkName: "component---src-pages-schlossinterieur-js" */),
  "component---src-pages-stuckbildwerk-js": () => import("./../../../src/pages/stuckbildwerk.js" /* webpackChunkName: "component---src-pages-stuckbildwerk-js" */),
  "component---src-pages-tafelmalerei-js": () => import("./../../../src/pages/tafelmalerei.js" /* webpackChunkName: "component---src-pages-tafelmalerei-js" */),
  "component---src-pages-untersuchung-js": () => import("./../../../src/pages/untersuchung.js" /* webpackChunkName: "component---src-pages-untersuchung-js" */),
  "component---src-pages-wandgemaelde-js": () => import("./../../../src/pages/wandgemaelde.js" /* webpackChunkName: "component---src-pages-wandgemaelde-js" */),
  "component---src-pages-werksprozess-js": () => import("./../../../src/pages/werksprozess.js" /* webpackChunkName: "component---src-pages-werksprozess-js" */)
}

