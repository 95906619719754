import "./src/pages/index.css";
require('typeface-gurajada');
require('typeface-noto-sans');

export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!(`IntersectionObserver` in window)) {
      require(`intersection-observer`)
      console.log(`# IntersectionObserver is polyfilled!`)
    }
  }